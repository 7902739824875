import React, { useState, useEffect } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { deleteUser, getAll } from 'api/api-users';
import { Table, Tag, Space, Button, Modal, Form, Input, Card, Badge } from 'antd';
import { formatPrice } from 'utils';
import { Link } from 'react-router-dom';
import classes from './style.module.scss';
import { PATHS } from 'constants/routes';
import useOptions from 'modules/options/options.hook';

const confirm = Modal.confirm;


const ActionCell = ({ item, onChange }: any) => {
	const [isRequesting, setIsRequesting] = useState(false);
	const onClickBtn = () => {
		confirm({
			centered:true,
			title: 'Are you sure you want to delete?',
			okText: 'Delete',
			okType: 'default',
			cancelText: 'No, do not delete',
			okButtonProps:{
				style:{
					backgroundColor:'#fff'
				}
			},
			cancelButtonProps: {
				style:{
					backgroundColor:'#0ab068', color:'#fff'
				}
			},
			onOk() {
				setIsRequesting(true);
				deleteUser(item.id).then((res) => {
					setIsRequesting(false);
					onChange();
				});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	return (
		<Space size="middle">
			<Button loading={isRequesting} icon={<DeleteOutlined />} onClick={onClickBtn}>
				Delete
			</Button>
		</Space>
	);
};

const Index = () => {
	const [tableData, setTableData] = useState([]);

	const { options: { tags }} = useOptions();
	const loadTable = () => {
		getAll().then((res) => {
			console.log('get all users ===>', res.body);
			setTableData(res.body);
		});
	};

	const columns = [
		{
			title: 'Consultant',
			dataIndex: 'name',
			key: 'name',
			width: '15%',
			render: (text: string, item: any) => <Link to={`${PATHS.DASHBOARD}${PATHS.USERS}/${item.id}`}>{text}</Link>,
		},
		{
			title: 'Builder',
			dataIndex: 'location_tag_id',
			key: 'location_tag_id',
			width: '15%',
			render: (text: string, item: any) => {
				const tag = tags.find((ele:any)=>ele.id == item.location_tag_id);
				if(tag){
					return tag.tag_name;
				}
				return '';
			}
			// render: (text: string, item: any) => <Link to={`${PATHS.DASHBOARD}${PATHS.USERS}/${item.id}`}>{text}</Link>,
		},
		{
			title: 'User Name',
			dataIndex: 'username',
			key: 'name',
			width: '15%',
		},
		{
			title: 'Password',
			dataIndex: 'password',
			key: 'name',
			width: '15%',
		},
		{
			title: 'Account',
			dataIndex: 'name',
			key: 'name',
			width: '15%',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: '15%',
			render: (text:string, item:any) => (item.status == 'active')?<Badge status="success" />:(item.status == 'pending')?<Badge status="processing" />:<Badge status="error" />
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			width: '15%',
			render: (text:string, item:any) => (<a href = {`mailto: ${text}`}>{text}</a>)
		},
		{
			title: 'Action',
			key: 'action',
			width: '20%',
			render: (text: string, record: any) => (
				<ActionCell item={record} onChange={() => loadTable()} />
			),
		},
	];

	useEffect(() => {
		loadTable();
	}, []);

	const onCreateNewUser = () => {
		window.location.href = `${PATHS.DASHBOARD}/${PATHS.USERS}/0`;
	};

	return (
			
		<Card title = "Users" extra = {<Button onClick={onCreateNewUser} type="primary">New</Button>}>
			<Table 
				columns={columns}
				dataSource={tableData} 
				pagination = {
					{
						pageSize:100
					}
				}
			/>
		</Card>
	);
};

export default Index;
