import React, { useState, useEffect, useRef } from 'react';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import {getAll, deleteHousetype, updateHousetype, createHousetype, getStairTypes } from 'api/api-housetype';
import { Table, Tag, Space, Button, Modal, Form, Input, Card, Badge, InputNumber, Select, InputRef } from 'antd';
import { Link } from 'react-router-dom';
import classes from './style.module.scss';

import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import * as PricingAPI from 'api/api-pricing';
import useOptions from 'modules/options/options.hook';
import { PATHS } from 'constants/routes';
import { render } from '@testing-library/react';



const { Option } = Select;

const confirm = Modal.confirm;

interface Item {
    id: number;
    tag_type: string;
    tag_name: string;
    tag_description: string;
}


interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	form:any;
    editing: boolean;
    required: boolean;
    disabled: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text' | 'photo-picker' | 'price' | 'text-select' | 'tag' | 'stair-select' | 'builder-select' | 'housetype-select';
    record: any;
    index: number;
    children: React.ReactNode;
	options?:any[]
  }
  
const EditableCell: React.FC<EditableCellProps> = ({
	form,
	editing,
	dataIndex,
	title,
	inputType,
	disabled,
	record,
	index,
	children,
	options,
	required,
	...restProps
}) => {

	let inputNode = <Input disabled = {disabled} />;

	if(inputType == 'number'){
		inputNode = <InputNumber min = {0} />;
	}

	if(inputType == 'text-select'){
		inputNode = <Select>{options?options.map((ele:any)=><Option value = {ele}>{ele}</Option>):[]}</Select>;
	}

	if(inputType == 'housetype-select'){
		inputNode = <Select 
			showSearch 
			style={{ width: 200 }}
			optionFilterProp="label"
			// filterSort={(optionA, optionB) =>
			// 	(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
			options={options?.filter(ele => ele.title !== record.title).map(ele => ({
				value: ele.id,
				label: ele.title
			}))}
		/>;
	}

	if(inputType == 'stair-select'){
		inputNode = <Select>{options?options.map((ele:any)=><Option value = {ele.id}>{ele.stair_type}</Option>):[]}</Select>;
	}
	if(inputType == 'builder-select'){
		inputNode = <Select>{options?options.map((ele:any)=><Option value = {ele.id}>{ele.tag_name}</Option>):[]}</Select>;
	}
	if(inputType == 'tag'){
		inputNode = <Select mode='multiple'>{options?options.map((ele)=><Option value = {ele.id}>{ele.tag_name}</Option>):[]}</Select>;
	}
	if(inputType == 'price'){
		inputNode = <Input type = "number" min = {0} suffix = "$"/>;
	}
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					style={{ margin: 0 }}
					rules={[
						{
							required,
							message: `Please Input ${title}!`,
						},
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};
const ActionCell = ({ item, editingId, onChange, onEditing, form }: any) => {

	
	const [isRequesting, setIsRequesting] = useState(false);
	const onDeleteBtn = () => {
		confirm({
			centered:true,
			title: 'Are you sure you want to delete?',
			okText: 'Delete',
			okType: 'default',
			cancelText: 'No, do not delete',
			okButtonProps:{
				style:{
					backgroundColor:'#fff'
				}
			},
			cancelButtonProps: {
				style:{
					backgroundColor:'#0ab068', color:'#fff'
				}
			},
			onOk() {
				setIsRequesting(true);
				deleteHousetype(item.id).then(()=>{
					setIsRequesting(false);
					onChange();
				});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const onClickEditBtn = () => {
		onEditing(item.id);
		form.setFieldsValue(item);
	};

	const onSave = async () => {
		setIsRequesting(true);
		const data = form.getFieldsValue();
		
		data.tags = [...data.range || [], ...data.shape || [], ...data.inclusion || [] ];

		try{
			if(editingId>0){
				await updateHousetype(editingId, data);
			}else{
				await createHousetype(data);
			}
		}catch(err:any){
			console.log(err);
		}
		setIsRequesting(false);
		onEditing(-1);
		form.resetFields();
		onChange();
	};

	const onCancel = () => {
		onEditing(-1);
	};

	if(editingId == item.id){
		return (
			<Space size="middle">
				<Button loading={isRequesting} icon={<CheckCircleOutlined />} onClick={onSave} />
				<Button loading={isRequesting} icon={<CloseCircleOutlined/>} onClick={onCancel} />
			</Space>
		);    
	}

	return (
		<Space size="middle">
			<Button loading={isRequesting} icon={<EditOutlined />} onClick={onClickEditBtn} />
			<Button loading={isRequesting} icon={<DeleteOutlined/>} onClick={onDeleteBtn} />
		</Space>
	);
};

const Index = () => {

	const [form] = Form.useForm();
	const [editingId, setEditingId] = useState(-1);
	const [tableData, setTableData] = useState<any>([]);
	const { options: { tags }} = useOptions();
	const [query, setQuery] = useState('');
	const [defaultStairTypes, setDefaultStairTypes] = useState<any[]>([]);
	

	const loadTable = () => {
		getAll().then((res) => {
			res.body.forEach((element:any) => {
				const tempShapeTags:any = [];
				const tempShapeTagStrArr:any = [];
				const tempInclusionTags:any = [];
				element.tags.split(',').forEach((tag_id:any) => {
					tags.forEach((tag:any) => {
						if(tag.id == tag_id && tag.tag_type == 'shape'){
							tempShapeTags.push(tag.id);
							tempShapeTagStrArr.push(tag.tag_name);
						}
						if(tag.id == tag_id && tag.tag_type == 'inclusion'){
							tempInclusionTags.push(tag.id);
						}
					});
				});
				element.inclusion = tempInclusionTags;
				element.shape = tempShapeTags;
				element.shapeArr = tempShapeTagStrArr;

				element.builder_tag = tags.find((ele:any)=>ele.id == element.builder_id);
			});
			setTableData(res.body);
		});
	};

	const columns = [
		{
			title: 'BASE SPEC & PRICING',
			className: classes.tableBase,
			children: [
				{
					title: 'House Type',
					dataIndex: 'title',
					key: 'title',
					editable:true,
					sorter: (a:any, b:any) => ('' + a.title).localeCompare(b.title),
					render: (text: string, item: any) => <Link to={`${PATHS.DASHBOARD}${PATHS.HOUSETYPES}/${item.id}`}>{text}</Link>,
				},
				{
					title: 'Clean Title',
					dataIndex: 'clean_title',
					key: 'clean_title',
					editable:true,
				},
				{
					title: 'Builder',
					dataIndex: 'builder_id',
					key: 'builder_id',
					inputType:'builder-select',
					options: tags.filter((ele:any)=>ele.tag_type == 'builder'),
					editable: true,
					render: (text: string, item: any) => {
						if(item.builder_tag){
							return item.builder_tag.tag_name;
						}
						return '';
					}
				},
				{
					title: 'Inclusion',
					dataIndex: 'inclusion',
					key: 'inclusion',
					editable:true,
					inputType:'tag',
					options:tags.filter((ele:any)=>ele.tag_type == 'inclusion'),
					render:(text:string, item:any)=>{
						const arr:any = [];
						const tagArr = tags.filter((ele:any)=>ele.tag_type == 'inclusion');
						item.inclusion.forEach((element:any) => {
							const tag = tagArr.find((tag:any)=>(tag.id == element));
							if(tag)arr.push(tag.tag_name);
						});
						return arr.join(',');
					}
				},
				{
					title: 'Shape',
					dataIndex: 'shape',
					key: 'shape',
					editable:true,
					inputType:'tag',
					options:tags.filter((ele: any) => ele.tag_type == 'shape').sort((a: any, b: any) => a.tag_name.localeCompare(b.tag_name)),
					render:(text:string, item:any)=>{
						const arr:any = [];
						const tagArr = tags.filter((ele:any)=>ele.tag_type == 'shape');
						item.shape.forEach((element:any) => {
							const tag = tagArr.find((tag:any)=>(tag.id == element));
							if(tag)arr.push(tag.tag_name);
						});
						return arr.join(',');
					}
				},
				{
					title: 'Bal M',
					dataIndex: 'bal_m',
					key: 'bal_m',
					inputType:'number',
					editable:true,
				}
			],
			editable:true,
		},
		{
			title: 'OPTIONS ALLOWED',
			className: classes.tableAllowed,
			children: [
				{
					title: 'Allow Feature Steps',
					dataIndex: 'allow_feature_steps',
					key: 'allow_feature_steps',
					inputType:'text-select',
					options: ['No', 'Yes 1', 'Yes 2', 'Yes 1-B', 'Yes 2-A (Ltd)', 'Yes 2-B'],
					editable:true,
				},
				{
					title: 'Allow Landings',
					dataIndex: 'allow_landings',
					inputType:'text-select',
					options: ['No','Small','Medium','Large', 'Full Width Landing'],
					key: 'allow_landings',
					editable:true,
				},
			],
			editable:true,
		},
		{
			title: 'OPTIONS APPLIED TO BASE',
			className: classes.tableApplied,
			children: [
				{
					title: 'Stair Type',
					dataIndex: 'base_stair_type_id',
					inputType:'stair-select',
					options:defaultStairTypes,
					key: 'base_stair_type_id',
					editable:true,
					render: (text:string, item:any) => <>{item.stair_type}</>
				},
				{
					title: 'Carpet',
					dataIndex: 'carpet',
					key: 'carpet',
					inputType:'text-select',
					options: ['Step 1', 'Step 2','Step 3', 'Off'],
					editable:true,				
				},
				{
					title: 'Outer Stringer',
					dataIndex: 'outer_stringer',
					key: 'outer_stringer',
					editable:true,				
					disabled:true,
				},
				{
					title: 'Outer Stringer Colour',
					dataIndex: 'left_stringer_color',
					key: 'left_stringer_color',
					editable:true,					
				},
				{
					title: 'Wall Stringer',
					dataIndex: 'wall_stringer',					
					key: 'wall_stringer',
					editable:true,
					disabled:true,
				},
				{
					title: 'Wall Stringer Colour',
					dataIndex: 'right_stringer_color',					
					key: 'right_stringer_color',
					editable:true,
				},
				{
					title: 'Tread Colour',
					dataIndex: 'tread_color',
					key: 'tread_color',
					editable:true,
				},
				{
					title: 'Riser Colour',
					dataIndex: 'riser_color',
					key: 'riser_color',
					editable:true,
				},
				{
					title: 'Grade',
					dataIndex: 'stain_cover_grade',
					key: 'stain_cover_grade',
					inputType:'text-select',
					options: ['none', 'covergrade','staingrade', 'laminate'],
					editable:true,
				},
				{
					title: 'Balustrade',
					dataIndex: 'balustrade',
					key: 'balustrade',
					editable:true,
				},
				{
					title: 'Balustrade colour',
					dataIndex: 'balustrade_color',
					key: 'balustrade_color',
					editable:true,
				},
				{
					title: 'Handrail',
					dataIndex: 'handrail',
					key: 'handrail',
					editable:true,
				},
				{
					title: 'Handrail Colour',
					dataIndex: 'handrail_color',
					key: 'handrail_color',
					editable:true,
				},
				{
					title: 'Post',
					dataIndex: 'post',
					key: 'post',
					editable:true,
				},
				{
					title: 'Post Colour',
					dataIndex: 'post_color',
					key: 'post_color',
					editable:true,
				},
				{
					title: 'Post Top',
					dataIndex: 'post_top',
					key: 'post_top',
					editable:true,
				},
				{
					title: 'Post Top Colour',
					dataIndex: 'post_top_color',
					key: 'post_top_color',
					editable:true,
				},
				{
					title: 'Post Position',
					dataIndex: 'post_position',
					key: 'post_position',
					editable:true,
				},
				{
					title: 'Margins',
					dataIndex: 'margins',
					key: 'margins',
					editable:true,
				},
				{
					title: 'Capping',
					dataIndex: 'capping',
					key: 'capping',
					editable:true,
					inputType:'text-select',
					options: ['None', 'Step 1 Closed Riser','Step 2 Closed Riser', 'Step 3 Closed Riser', 'Step 1 Open Riser', 'Step 2 Open Riser', 'Step 3 Open Riser'],
				},
				{
					title: 'Margins Colour',
					dataIndex: 'margin_color',
					key: 'margin_color',
					editable:true,
				},
				{
					title: 'Step 1 Feat',
					dataIndex: 'step_1_feat',
					key: 'step_1_feat',
					editable:true,
					required:false,
				},
				{
					title: 'Step 1 Grade',
					dataIndex: 'step_1_stain_cover_grade',
					key: 'step_1_stain_cover_grade',
					editable:true,
					required:false,
				},
				{
					title: 'Step 2 Feat',
					dataIndex: 'step_2_feat',
					key: 'step_2_feat',
					editable:true,
					required:false,
				},
				{
					title: 'Step 2 Grade',
					dataIndex: 'step_2_stain_cover_grade',
					key: 'step_2_stain_cover_grade',
					editable:true,
					required:false,
				},
				{
					title: 'Vic Ash Posts',
					dataIndex: 'vic_ash_posts',
					key: 'vic_ash_posts',
					editable:true,
					inputType:'text-select',
					options: ['Yes', 'No'],
				},
			],
			editable:true,
		},
		{
			title: 'ACTIONS',
			className: classes.tableActions,
			children: [
				{
					title: 'Active',
					dataIndex: 'active',
					key: 'active',
					inputType:'text-select',
					options: ['y','n'],
					editable:true,
					render: (text:string, item:any) => (item.active=='y')?<Badge status="success" />:<Badge status="default" />
				},
				{
					title: 'Action',
					key: 'action',
					//fixed:'right',
					render: (text: string, record: any) => (
						<ActionCell 
							form = {form}
							item={record}
							editingId = {editingId}  
							onChange={() => loadTable()} 
							onEditing = {(val:number)=>{
								setEditingId(val);
								if(val < 0 && editingId == 0){
									tableData.shift();
									setTableData([...tableData]);
								}
							}}
						/>
					),
				},
			],
			editable:true,
		},
	];

	const mapColumns = (col:any) => {
		if (!col.editable) {
			return col;
		}
		const newCol = {
			...col,
			onCell: (record: Item) => ({
				form: form,
				record,
				disabled: col.disabled,
				required: (typeof col.required !== undefined)?col.required:true,
				inputType: (col.inputType)?col.inputType:'text',
				dataIndex: col.dataIndex,
				options:col.options,
				title: col.title,
				editing: (record.id == editingId),
			}),
		};
		if (col.children) {
			newCol.children = col.children.map(mapColumns);
		}
		return newCol;
	};

	const mergedColumns = columns.map(mapColumns);

	useEffect(() => {
		async function asyncLoad() {
			// get default stair types
			try {
				const result = await getStairTypes();
				setDefaultStairTypes(result.body);			
			}catch(error:any){
				console.log(error.message);
			}
		}
		if(tags.length > 0){
			loadTable();
		}
		asyncLoad();
	}, [tags]);


	const onChangeSearchInput = (e:any) => {

		setQuery(e.target.value);
		
	};

	const onCreateNewHouseType = () => {
		// window.location.href(`${match.path}/0`);
		setEditingId(0);
		const newData = {
			id: 0,
			title:'New Home Model',
			range:[],
			shape:[],
			state:[],
			inclusion:[]
		};
		if(tableData.findIndex((ele:any)=>ele.id == 0) == -1){
			setTableData([newData, ...tableData]);
		}
	};

	const onChangeFormValues = (value:any) => {
		if(value.base_stair_type_id){
			// prefill form
			const stairType = defaultStairTypes.find(ele=>ele.id == value.base_stair_type_id);
			// console.log(out_stringer);
			form.setFieldsValue({ outer_stringer:stairType.outer_stringer, wall_stringer:stairType.wall_stringer });
		}
	};

	const ftableData = tableData.filter((ele:any)=>ele.title.toLowerCase().includes(query.toLowerCase()) || ele.builder_tag.tag_name.toLowerCase().includes(query.toLowerCase()));

	return (
		<Card title = "House types" extra = {<Button type = "primary" onClick = {onCreateNewHouseType}>Add New</Button>}>
			<div className ="table-header">
				<Input.Search style={{ width: '40%' }} onChange = {onChangeSearchInput} />
			</div>
			<Form form = {form} onValuesChange = {onChangeFormValues} >
				<Table
					size="small"
					components={{
						body: {
							cell: EditableCell,
						},
					}}
					bordered
					rowKey={'id'}
					pagination = {
						{
							pageSize:100
						}
					}
					scroll = {{x:'3500px'}}
					columns={mergedColumns}
					dataSource={ftableData} 
				/>
			</Form>
		</Card>
	);
};

export default Index;
